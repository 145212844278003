import isEmpty from 'lodash.isempty';
import { formatPrice } from '../priceFormatter/priceFormatter.utils';
import {
  formatDayMonthYear,
  getCourseRangeDate,
} from '../dateFormatter/dateFormatter.utils';
import { CourseProduct } from '@/shared/types';
import { CourseProductSelection } from '@/components/cards/booking-date-selection-card/bookingDateSelectionCard.types';

export const isCourseProductAvailable = (
  courseProduct: CourseProduct
): boolean =>
  courseProduct.isAvailable &&
  !courseProduct.isFullyBooked &&
  !courseProduct.isArchived &&
  !courseProduct.isEnrolmentDeadlineExceeded &&
  !courseProduct.isInPreview;

export const checkCourseProductsAvailability = (
  courseProducts: CourseProduct[]
): boolean => !isEmpty(courseProducts.find(isCourseProductAvailable));

export const checkCourseCanBeBooked = (
  courseProducts: CourseProduct[]
): boolean => {
  return !isEmpty(
    courseProducts.find(
      (courseProduct) =>
        courseProduct.isAvailable &&
        !courseProduct.isArchived &&
        !courseProduct.isEnrolmentDeadlineExceeded &&
        !courseProduct.isFullyBooked
    )
  );
};

export const mapAvailableCourseProductDate = (
  product: CourseProduct
): CourseProductSelection => {
  const enrolmentDeadline =
    product.enrolmentDeadline && formatDayMonthYear(product.enrolmentDeadline);
  const formattedPrice =
    product.price && formatPrice(product.price.amount, product.price.currency);
  const courseDuration = getCourseRangeDate({
    startDate: product.startDate,
    endDate: product.endDate,
  })!;

  return {
    ...product,
    enrolmentDeadline,
    formattedPrice,
    courseDuration,
  };
};
