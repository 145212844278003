
import {
  defineComponent,
  PropType,
  getCurrentInstance,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { renderRichTextDocumentToHtml } from '../contentful/rich-text/richText.utils';
import Collapsible from './Collapsible.vue';

type CallbackOnEnabled = () => void;

export default defineComponent({
  components: {
    Collapsible,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    expanderContent: {
      type: Object as PropType<ContentfulDocument>,
      required: true,
    },
    expanderTitle: {
      type: String,
      required: true,
    },
    isCheckBulletStyle: {
      type: Boolean,
      default: false,
    },
    onEnabled: {
      type: Function as PropType<CallbackOnEnabled>,
      default: () => {},
    },
  },
  setup: (props) => {
    const instance = getCurrentInstance();
    const { $store } = instance!.proxy;

    const activeExpanderId = computed(
      () => $store.state.expander.activeExpanderId
    );

    const toggleExpanded = (id: string, isExpanded: boolean) => {
      if (isExpanded) {
        $store.dispatch('expander/setActiveExpanderId', id);
      }
    };

    const expanderContentPlainText = renderRichTextDocumentToHtml(
      props.expanderContent
    );

    onMounted(() => {
      if (activeExpanderId.value) {
        $store.dispatch('expander/setActiveExpanderId', '');
      }
    });

    return {
      activeExpanderId,
      toggleExpanded,
      expanderContentPlainText,
    };
  },
});
