type FormatPriceOpts = {
  showFractions?: boolean;
};

const CurrencyPrefixes: Record<string, string> = {
  USD: 'US$',
  AUD: 'AU$',
  CAD: 'CAD',
  EUR: '€',
  GBP: '£',
};
// reference => https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
export const formatPriceNumber = (price: number) => {
  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export function formatPrice(
  price: number,
  currency: string,
  opts?: FormatPriceOpts
): string {
  const prefix = CurrencyPrefixes[currency];
  const showFractions = opts?.showFractions || false;

  if (!prefix) {
    throw new Error('Unsupported currency');
  }

  const formattedPriceNumber = formatPriceNumber(price);
  const finalPriceNumber = showFractions
    ? formattedPriceNumber
    : formattedPriceNumber.split('.')[0];

  return `${prefix}${finalPriceNumber}`;
}

export const formatPriceRange = (
  prices: number[],
  currency: string,
  opts?: FormatPriceOpts
): string | null => {
  if (!prices?.length) {
    return null;
  }

  const minAmount = Math.min(...prices);
  const maxAmount = Math.max(...prices);

  const formattedMinAmount = formatPrice(minAmount, currency, opts);

  if (minAmount === maxAmount) {
    return formattedMinAmount;
  }

  const formattedMaxAmount = formatPrice(maxAmount, currency, opts);

  return `${formattedMinAmount} - ${formattedMaxAmount}`;
};
