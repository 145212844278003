// @ts-nocheck
import { BookingState, StoreActionContext } from '@/shared/types/store';

export const state = (): BookingState => ({
  selectedCourseDateProductSku: '',
  courseCostCurrency: '',
});

export const mutations = {
  SET_SELECTED_COURSE_DATE_PRODUCT_SKU(
    state: BookingState,
    productSku: string
  ) {
    state.selectedCourseDateProductSku = productSku;
  },
  SET_COURSE_COST_CURRENCY(state: BookingState, courseCostCurrency: string) {
    state.courseCostCurrency = courseCostCurrency;
  },
};

export const actions = {
  async setSelectedCourseDateProductSku(
    context: StoreActionContext,
    productSku: string
  ): Promise<void> {
    await this.$axios.post('/api/v1/ecommerce/save-product-sku', {
      productSku,
    });
    return context.commit('SET_SELECTED_COURSE_DATE_PRODUCT_SKU', productSku);
  },
  async getSelectedCourseDateProductSku(
    context: StoreActionContext
  ): Promise<void> {
    const { data } = await this.$axios.get(
      '/api/v1/ecommerce/get-saved-product-sku'
    );
    return context.commit(
      'SET_SELECTED_COURSE_DATE_PRODUCT_SKU',
      data.productSku
    );
  },
  setCourseCostCurrency(
    context: StoreActionContext,
    courseCostCurrency: string
  ): void {
    context.commit('SET_COURSE_COST_CURRENCY', courseCostCurrency);
  },
};
