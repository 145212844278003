
import { defineComponent } from '@nuxtjs/composition-api';
import AcademicCapIcon from './components/AcademicCapIcon.vue';
import AccessBrochureIcon from './components/AccessBrochureIcon.vue';
import AlertIcon from './components/AlertIcon.vue';
import AmexIcon from './components/AmexIcon.vue';
import BankTransferIcon from './components/BankTransferIcon.vue';
import BookACallIcon from './components/BookACallIcon.vue';
import CalendarIcon from './components/CalendarIcon.vue';
import CardIcon from './components/CardIcon.vue';
import CertificateIcon from './components/CertificateIcon.vue';
import CheckmarkBlackIcon from './components/CheckmarkBlackIcon.vue';
import CircleCrossIcon from './components/CircleCrossIcon.vue';
import CircleTickIcon from './components/CircleTickIcon.vue';
import ClockIcon from './components/ClockIcon.vue';
import CommitmentIcon from './components/CommitmentIcon.vue';
import ConfirmationCheckmarkIcon from './components/ConfirmationCheckmarkIcon.vue';
import CourseRequirementIcon from './components/CourseRequirementIcon.vue';
import CrossBlackIcon from './components/CrossBlackIcon.vue';
import CrossIcon from './components/CrossIcon.vue';
import DarkTickSuccessIcon from './components/DarkTickSuccessIcon.vue';
import DownloadIcon from './components/DownloadIcon.vue';
import DropdownArrowDownIcon from './components/DropdownArrowDownIcon.vue';
import DropdownArrowUpIcon from './components/DropdownArrowUpIcon.vue';
import DropdownBlackArrowDownIcon from './components/DropdownBlackArrowDownIcon.vue';
import DropdownBlackArrowUpIcon from './components/DropdownBlackArrowUpIcon.vue';
import DropdownWhiteArrowDownIcon from './components/DropdownWhiteArrowDownIcon.vue';
import DropdownWhiteArrowUpIcon from './components/DropdownWhiteArrowUpIcon.vue';
import ExpandIcon from './components/ExpandIcon.vue';
import ExternalLinkIcon from './components/ExternalLinkIcon.vue';
import FacebookIcon from './components/FacebookIcon.vue';
import GlobIcon from './components/GlobIcon.vue';
import HamburgerMenuIcon from './components/HamburgerMenuIcon.vue';
import InfoIcon from './components/InfoIcon.vue';
import LinkedInBlackIcon from './components/LinkedInBlackIcon.vue';
import LinkedinIcon from './components/LinkedinIcon.vue';
import LoaderIcon from './components/LoaderIcon.vue';
import MastercardIcon from './components/MastercardIcon.vue';
import MinusIcon from './components/MinusIcon.vue';
import MoneyIcon from './components/MoneyIcon.vue';
import NextArrowBlackIcon from './components/NextArrowBlackIcon.vue';
import NextArrowIcon from './components/NextArrowIcon.vue';
import NextArrowWhiteIcon from './components/NextArrowWhiteIcon.vue';
import PayByQuoteIcon from './components/PayByQuoteIcon.vue';
import PaypalIcon from './components/PaypalIcon.vue';
import PencilEditIcon from './components/PencilEditIcon.vue';
import PhoneIcon from './components/PhoneIcon.vue';
import PlusIcon from './components/PlusIcon.vue';
import PolygonIcon from './components/PolygonIcon.vue';
import PrevArrowBlackIcon from './components/PrevArrowBlackIcon.vue';
import PrevArrowIcon from './components/PrevArrowIcon.vue';
import PrevArrowWhiteIcon from './components/PrevArrowWhiteIcon.vue';
import QuoteIcon from './components/QuoteIcon.vue';
import SearchIcon from './components/SearchIcon.vue';
import ShareBlackIcon from './components/ShareBlackIcon.vue';
import ShareWhiteIcon from './components/ShareWhiteIcon.vue';
import ShortLineIcon from './components/ShortLineIcon.vue';
import SolidArrowDownIcon from './components/SolidArrowDownIcon.vue';
import Step1Icon from './components/Step1Icon.vue';
import Step2Icon from './components/Step2Icon.vue';
import Step3Icon from './components/Step3Icon.vue';
import SuccessIcon from './components/SuccessIcon.vue';
import TickIcon from './components/TickIcon.vue';
import TwitterBlackIcon from './components/TwitterBlackIcon.vue';
import TwitterIcon from './components/TwitterIcon.vue';
import VideoPlayIcon from './components/VideoPlayIcon.vue';
import VisaIcon from './components/VisaIcon.vue';
import WatchIcon from './components/WatchIcon.vue';
import WebsiteBlackIcon from './components/WebsiteBlackIcon.vue';
import WhylearnCertificateIcon from './components/WhylearnCertificateIcon.vue';
import WhylearnClockIcon from './components/WhylearnClockIcon.vue';
import WhylearnCommunityIcon from './components/WhylearnCommunityIcon.vue';
import WhylearnTeachersIcon from './components/WhylearnTeachersIcon.vue';
import YouTubeBlackIcon from './components/YouTubeBlackIcon.vue';

export default defineComponent({
  components: {
    AcademicCapIcon,
    AccessBrochureIcon,
    AlertIcon,
    AmexIcon,
    BankTransferIcon,
    BookACallIcon,
    CalendarIcon,
    CardIcon,
    CertificateIcon,
    CheckmarkBlackIcon,
    CircleCrossIcon,
    CircleTickIcon,
    ClockIcon,
    CommitmentIcon,
    ConfirmationCheckmarkIcon,
    CourseRequirementIcon,
    CrossBlackIcon,
    CrossIcon,
    DarkTickSuccessIcon,
    DownloadIcon,
    DropdownArrowDownIcon,
    DropdownArrowUpIcon,
    DropdownBlackArrowDownIcon,
    DropdownBlackArrowUpIcon,
    DropdownWhiteArrowDownIcon,
    DropdownWhiteArrowUpIcon,
    ExpandIcon,
    ExternalLinkIcon,
    FacebookIcon,
    GlobIcon,
    HamburgerMenuIcon,
    InfoIcon,
    LinkedInBlackIcon,
    LinkedinIcon,
    LoaderIcon,
    MastercardIcon,
    MinusIcon,
    MoneyIcon,
    NextArrowBlackIcon,
    NextArrowIcon,
    NextArrowWhiteIcon,
    PayByQuoteIcon,
    PaypalIcon,
    PencilEditIcon,
    PhoneIcon,
    PlusIcon,
    PolygonIcon,
    PrevArrowBlackIcon,
    PrevArrowIcon,
    PrevArrowWhiteIcon,
    QuoteIcon,
    SearchIcon,
    ShareBlackIcon,
    ShareWhiteIcon,
    ShortLineIcon,
    SolidArrowDownIcon,
    Step1Icon,
    Step2Icon,
    Step3Icon,
    SuccessIcon,
    TickIcon,
    TwitterBlackIcon,
    TwitterIcon,
    VideoPlayIcon,
    VisaIcon,
    WatchIcon,
    WebsiteBlackIcon,
    WhylearnCertificateIcon,
    WhylearnClockIcon,
    WhylearnCommunityIcon,
    WhylearnTeachersIcon,
    YouTubeBlackIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
