// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../graphics/tick-dark-green.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cms-container p{margin-bottom:30px}.cms-container b{font-weight:500}.cms-container i{font-style:italic}.cms-container u{-webkit-text-decoration:underline;text-decoration:underline}.cms-container h2{font-size:1.625rem;font-weight:500;line-height:2.375rem;margin-bottom:30px}@media (min-width:768px){.cms-container h2{font-size:2.375rem;line-height:2.813rem}}.cms-container h3{font-size:1.375rem;font-weight:500;line-height:1.625rem;margin-bottom:30px}@media (min-width:768px){.cms-container h3{font-size:1.875rem;line-height:2.813rem}}.cms-container h4{font-size:1.125rem;font-weight:500;line-height:1.313rem;margin-bottom:30px}@media (min-width:768px){.cms-container h4{font-size:1.375rem;line-height:1.625rem}}.cms-container ol{list-style-position:inside;list-style-type:decimal}.cms-container ol>li>ul{margin-left:20px;margin-top:10px}.cms-container ul{list-style-position:inside;list-style-type:disc}.cms-container ol>li>ol,.cms-container ol>li>ul,.cms-container ul>li>ol,.cms-container ul>li>ul{margin-left:20px;margin-top:10px}.cms-container li{margin-bottom:10px}.cms-container li p{display:inline}.embedded-expander+:not(.embedded-expander),:not(.embedded-expander)+.embedded-expander{margin-top:30px}@media (min-width:768px){.embedded-expander+:not(.embedded-expander),:not(.embedded-expander)+.embedded-expander{margin-top:50px}}.cms-container .expander-custom-bullets ul{list-style-type:none;padding:0}.cms-container .expander-custom-bullets ul li{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;background-position-y:-1px;line-height:28px;margin-left:-4px;min-height:28px;padding-left:42px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
