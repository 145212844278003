export enum FeatureFlagName {
  USE_CAPTCHA_FOR_AUTOMATION_TESTING = 'UseCaptchaForAutomationTesting',
  USE_GROUP_BOOKING = 'UseGroupBooking',
  USE_PAY_BY_QUOTE = 'UsePayByQuote',
  USE_SOCIAL_SHARE_BUTTON = 'UseSocialShareButton',
  USE_BLOG = 'UseBlog',
  USE_B2B_PAGES = 'UseB2bPages',
  USE_PAYPAL_PAYMENT = 'UsePayPalPayment',
  EMERGENCY_PAYMENT_FUNCTIONALITY = 'EmergencyPaymentFunctionality',
  ZENDESK_REDIRECT = 'EnableZendeskRedirects',
  DISABLE_COURSE_DATES = 'DisableCourseDateSelection',
  ECOMMERCE = 'Ecommerce',
}
